<template>
  <v-container fluid>
    <Header headerClass="qualita-aria" title="Qualità dell'aria" />
    <v-container page id="contenuto">
      <v-row>
        <v-col class="col-md-9 col-12">
          <h2>Accesso ai dati misurati</h2>
          <p class="mt-40">
            Accesso in tempo reale a tutte le informazioni rilevate dal "Sistema
            di Rilevamento della Qualità dell'Aria".
          </p>
          <v-tabs grow>
            <v-tab> Misure </v-tab>
            <v-tab> Indicatori </v-tab>
            <v-tab-item>
              <RicercaMisure />
            </v-tab-item>
            <v-tab-item>
              <RicercaIndicatori />
            </v-tab-item>
          </v-tabs>
        </v-col>
        <v-col class="col-md-3 col-12 col-dx">
          <MenuQualitaAria page="ricerca-dati" />
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Header from "@/components/layout/Header";
import MenuQualitaAria from "@/views/qualita-aria/MenuQualitaAria";
import RicercaMisure from "@/components/qualita-aria/dati/RicercaMisure";
import RicercaIndicatori from "@/components/qualita-aria/dati/RicercaIndicatori";

export default {
  components: {
    Header,
    MenuQualitaAria,
    RicercaMisure,
    RicercaIndicatori
  }
};
</script>
